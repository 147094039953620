import { SubscribeFormAside } from 'components/SubscribeForm';
import { TouchableOpacity, Text } from 'react-native';
import MobilePhoneView from 'components/MobilePhoneView';
import { ExpoCameraDemo, ExpoDarkModeSwitchDemo, ExpoGesturesDemo, ExpoSvgDemo, ExpoVideoDemo, ExpoImagePickerDemo } from "../../../../content/posts/2020-05-11-using-expo-in-gatsby/components";
import * as React from 'react';
export default {
  SubscribeFormAside,
  TouchableOpacity,
  Text,
  MobilePhoneView,
  ExpoCameraDemo,
  ExpoDarkModeSwitchDemo,
  ExpoGesturesDemo,
  ExpoSvgDemo,
  ExpoVideoDemo,
  ExpoImagePickerDemo,
  React
};