import { StarwarsHeroSliderDefault, StarwarsHeroSliderDelay, StarwarsHeroSliderDelayThrow, StarwarsHeroSliderIgnoring, StarwarsHeroSliderAborting, StarwarsHeroSliderAbortingSafe } from "../../../../content/posts/2019-08-30-handling-race-conditions-in-react/components";
import { SubscribeFormAside } from 'components/SubscribeForm';
import * as React from 'react';
export default {
  StarwarsHeroSliderDefault,
  StarwarsHeroSliderDelay,
  StarwarsHeroSliderDelayThrow,
  StarwarsHeroSliderIgnoring,
  StarwarsHeroSliderAborting,
  StarwarsHeroSliderAbortingSafe,
  SubscribeFormAside,
  React
};